<div
  class="cp-phone-input-comp cp-custom-mat-input"
  [formGroup]="phoneInputForm"
  (focusout)="onFocusOut($event)"
  (focusin)="onFocusIn()"
>
  <span #countryCodeContainer class="cursor-pointer">
    <cp-dropdown
      [data]="itemsList | countryCodeOptions"
      [multiple]="false"
      formControlName="countryCode"
    >
      <ng-template #selectedTemplate>
        <div class="display-flex align-items-center cp-text-overflow-ellipses">
          <cp-flag-icons
            flagClass="ff-md"
            [isoCountryCode]="getFlag"
            class="cp-mr-1"
          ></cp-flag-icons>
          {{ countryCode.value }}
        </div>
      </ng-template>

      <ng-template #optionTemplate let-option>
        <ng-template
          [ngTemplateOutlet]="phoneOption"
          [ngTemplateOutletContext]="{ option: option }"
        ></ng-template>
      </ng-template>
    </cp-dropdown>
  </span>
  <span>
    <img
      src="assets/icons/whatsapp.svg"
      *ngIf="!isIndianCountryCode"
      alt=""
      srcset=""
      class="whatsapp-icon"
    />
    <input
      #mobileNumberInput
      class="cp-phone-input-comp__input"
      [ngClass]="{
        'whatsapp-icon-padding': !isIndianCountryCode
      }"
      formControlName="mobileNumber"
      type="tel"
      matInput
      [placeholder]="placeholder"
    />
    <ng-content></ng-content>
  </span>
</div>

<ng-template #phoneOption let-option="option">
  <div class="display-flex align-items-center" #countryEl>
    <cp-flag-icons
      flagClass="ff-md"
      [isoCountryCode]="getFlagOnCountryCode(option.value)"
    ></cp-flag-icons>
    <span class="cp-ml-1 cp-text-overflow-ellipses">{{ option?.label }}</span>
  </div>
</ng-template>
