import { Pipe, PipeTransform } from "@angular/core";
import { dropdownOutputType } from "../models/dropdown.model";

@Pipe({
  name: "checkedList",
})
export class CheckedListPipe implements PipeTransform {
  transform(item: any, selectedList: dropdownOutputType): boolean {
    if (Array.isArray(selectedList) && selectedList.includes(item)) return true;
    return false;
  }
}
