import { FocusMonitor } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgControl,
} from "@angular/forms";
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from "@angular/material/form-field";
import { SearchableDropdownComponent } from "@shared-modules/components/searchable-dropdown";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { timeZoneCountryCode } from "src/app/data/timezone.data";
import { countryList, topCountriesISO } from "../../phone-input.data";
import {
  CountryCodeDataInterface,
  PhoneInputInterface,
} from "../../phone-input.model";

@Component({
  selector: "cp-phone-input",
  templateUrl: "./phone-input.component.html",
  styleUrls: ["./phone-input.component.scss"],
  providers: [
    { provide: MatFormFieldControl, useExisting: PhoneInputComponent },
  ],
  host: {
    "[class.example-floating]": "shouldLabelFloat",
    "[id]": "id",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneInputComponent
  implements
    ControlValueAccessor,
    OnInit,
    MatFormFieldControl<PhoneInputInterface>,
    OnDestroy
{
  get defaultCountryCode(): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZoneCountryCode[timeZone] || "+91";
  }

  static nextId = 0;
  public itemsList: CountryCodeDataInterface[] = countryList;

  @ViewChild("countryCodeContainer") countryCodeContainer: ElementRef;
  @ViewChild("countryCodeSelect")
  countryCodeSelect: SearchableDropdownComponent;

  @ViewChild("mobileNumberInput") mobileNumberInput: ElementRef;

  public topCountriesISO = topCountriesISO;
  public topCountriesArr = [];

  public splcharsPattern = /[-+]/g; // regex expression, true when the charector is "+" or "-"
  public phonePattern = /[^0-9-+]/; // regex accetps only numeric and + or -

  @Input("errorStateMatcher") errorStateMatcher: any = {};

  public countryCodeFlagMap = new Map<string, string>();

  unsubscribe$: Subject<any> = new Subject<any>();

  phoneInputForm = new FormGroup({
    countryCode: new FormControl(this.defaultCountryCode),
    mobileNumber: new FormControl(""),
  });

  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = "phone-input";
  id = `phone-input-${PhoneInputComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    return !this.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  public focus() {
    this._focusMonitor.focusVia(this.mobileNumberInput, "program");
  }

  @Input("aria-describedby") userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder || "Enter phone number";
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled
      ? this.phoneInputForm.disable()
      : this.phoneInputForm.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): PhoneInputInterface | null {
    if (!this.mobileNumber.value) return null;
    return {
      countryCode: this.countryCode.value.slice(1),
      mobileNumber: this.mobileNumber.value,
    };
  }
  set value(val: PhoneInputInterface | null) {
    const countryCode =
      (val?.countryCode && "+" + val?.countryCode) || this.defaultCountryCode;
    this.countryCode.setValue(countryCode);
    this.mobileNumber.setValue(val?.mobileNumber);
  }

  @Input() whatsAppIconRequiredForForeignCountry: boolean = false;

  get errorState(): boolean {
    const parentErrors = this.errorStateMatcher.isErrorState?.(
      this.ngControl,
      this._parentFormGroup
    );
    return (this.ngControl.invalid && this.touched) || parentErrors;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private _parentFormGroup: FormGroupDirective,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnInit(): void {
    this.checkIndianCountryCode();
    this.initializeListeners();
    this.initializeValues();
  }

  initializeValues() {
    this.itemsList.forEach((el) => {
      this.countryCodeFlagMap.set(el.value, el.data.isoCountryCode);
      if (this.topCountriesISO.has(el.data.isoCountryCode)) {
        this.topCountriesArr.push(el);
      }
    });
  }

  public get getFlag() {
    return this.countryCodeFlagMap.get(this.countryCode.value);
  }

  public getFlagOnCountryCode(contryCode) {
    return this.countryCodeFlagMap.get(contryCode);
  }

  get countryCode(): FormControl {
    return this.phoneInputForm.get("countryCode") as FormControl;
  }

  get mobileNumber(): FormControl {
    return this.phoneInputForm.get("mobileNumber") as FormControl;
  }

  initializeListeners() {
    this.phoneInputForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.handleInput();
      });
    this.mobileNumber.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((mobileNumber) => {
        this.setInputField(mobileNumber);
      });
  }

  setInputField(mobileNumber: any) {
    this.removeInvalidInput(mobileNumber);
    this.handleInput();

    if (!mobileNumber) return;

    this.setCountryCode(mobileNumber);
  }

  // If phone number has country code then it would set country code dropdown
  setCountryCode(mobileNumber: any) {
    let code = null;
    if (
      mobileNumber?.replace(this.splcharsPattern, "")?.length === 5 &&
      mobileNumber.includes("+")
    ) {
      code = this.itemsList.find((el) =>
        mobileNumber.startsWith(el.value)
      )?.value;
    }
    if (!code) return;
    let replacedNumber = mobileNumber
      .replace(code, "")
      .replace(this.splcharsPattern, "");
    this.countryCode.setValue(code);
    this.mobileNumber.setValue(replacedNumber, { emitEvent: false });
  }

  // This code is written to not allow any invalid charectors for phone number
  // So when the user is typing any alphabet it would remove it.
  removeInvalidInput(mobileNumber: any) {
    this.mobileNumber.setValue(mobileNumber.replace(/[^0-9-+]/, ""), {
      emitEvent: false,
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.focused = false;
      this.updateTouched();
    }
  }

  updateTouched() {
    if (!this.mobileNumber.touched) return;
    this.touched = true;
    this.onTouched();
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".cp-phone-input-comp"
    )!;
    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }

  onContainerClick(event) {
    if (!this.countryCodeContainer.nativeElement.contains(event.target)) {
      this.focus();
    }
  }

  writeValue(value: PhoneInputInterface): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  isIndianCountryCode: boolean = true;
  INDIAN_COUNTRY_CODE = "+91";
  handleInput() {
    this.checkIndianCountryCode();
    this.onChange(this.value);
  }

  checkIndianCountryCode() {
    if (!this.whatsAppIconRequiredForForeignCountry) return;

    const countryCode = this.countryCode?.value;
    return ([this.isIndianCountryCode, this.placeholder] =
      countryCode === this.INDIAN_COUNTRY_CODE
        ? [true, "Enter your phone number"]
        : [false, "Enter WhatsApp number"]);
  }
}
