import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { CdkConnectedOverlay } from "@angular/cdk/overlay";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from "@angular/material/form-field";
import { BehaviorSubject, Observable, Subject, merge, of } from "rxjs";
import { map, skip, takeUntil } from "rxjs/operators";
import {
  DropdownFactoryInterface,
  DropdownInputInterface,
  dropdownOutputType,
} from "../../models/dropdown.model";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { itemSize } from "../../utils/dropdown-style.util";
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
} from "@angular/material/checkbox";

@Component({
  selector: "cp-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
  providers: [
    { provide: MatFormFieldControl, useExisting: DropdownComponent },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: "noop" } as MatCheckboxDefaultOptions,
    },
  ],
  host: {
    "[id]": "id",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent
  implements
    OnInit,
    AfterViewInit,
    ControlValueAccessor,
    MatFormFieldControl<dropdownOutputType>,
    OnDestroy,
    OnChanges
{
  isOpen = false;

  @Input("multiple") multiple: boolean = true;

  @Input("searchable") searchable: boolean = true;

  @Input("data") dropdownItems: DropdownInputInterface[] = [];

  @Input("searchPlaceholder") searchPlaceholder = "";

  public selected: dropdownOutputType = null;

  public dropdownWidth: number | null = null;

  public searchText: string = "";

  @ViewChild(CdkConnectedOverlay)
  private connecetedOverlay!: CdkConnectedOverlay | undefined;

  @ViewChild("dropdownCont", { static: true })
  dropdownCont!: ElementRef;

  @ViewChild("searchInput")
  searchInput!: ElementRef | undefined;

  @ContentChild("selectedTemplate", { static: false })
  selectedTemplateRef: TemplateRef<any>;
  @ContentChild("optionTemplate", { static: false })
  optionTemplateRef: TemplateRef<any>;

  ngDestroyed$ = new Subject();

  // This is in px
  public itemSize = itemSize;

  public $isPanelVisible: Observable<boolean> = of(false);

  public $backDrop: Observable<boolean> | undefined = of(false);

  private _focusBehaviorSubject = new BehaviorSubject(false);
  $focus = this._focusBehaviorSubject.asObservable();
  currentSelection: string = "";

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  scrolledToView: boolean = false;

  @ViewChildren("listItemElement")
  set setListItemElement(val: QueryList<ElementRef<HTMLElement>>) {
    // Focus on search input
    if (!val?.length || !this.selected?.length || this.scrolledToView) return;
    const firstItem = Array.isArray(this.selected)
      ? this.selected[0]
      : this.selected;

    const itemIndex = this.dropdownItems.findIndex(
      (el) => el.value === firstItem
    );
    if (itemIndex === -1) return;
    // Scroll to the selected element
    this.viewPort.scrollToIndex(itemIndex);
    this.scrolledToView = true;
  }

  constructor(
    private _cdr: ChangeDetectorRef,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dropdownItems && !changes.dropdownItems.isFirstChange()) {
      this._dropdownFactory.restructureSelection();
    }
  }
  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
    this.stateChanges.complete();
  }

  static nextId = 0;
  stateChanges = new Subject<void>();

  @Input()
  get value(): dropdownOutputType {
    return this.selected;
  }
  set value(selected: dropdownOutputType) {
    this.selected = selected;
    this.stateChanges.next();
  }

  id: string = `cp-dropdown-${DropdownComponent.nextId++}`;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string = "Select options";

  focused: boolean = false;

  touched: boolean = false;

  onChange = (_: dropdownOutputType) => {};
  onTouched = () => {};

  get empty() {
    return !this.selected || !this.selected?.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  get errorState(): boolean {
    return this.selected === undefined && this.touched;
  }

  controlType = "cp-mat-dropdown";
  @Input("aria-describedby") userAriaDescribedBy: string;
  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".cp-mat-dropdown-container"
    )!;
    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }
  onContainerClick(): void {
    this.onFocus();
  }

  onFocusOut() {
    this.touched = true;
    this.focused = false;
    this.searchText = "";
    this.scrolledToView = false;
    this.onTouched();
    this.stateChanges.next();
  }

  writeValue(value: dropdownOutputType): void {
    this._dropdownFactory.writeValue(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  ngAfterViewInit(): void {
    this.initializeObservables();
    this.initializeListeners();
    this._cdr.detectChanges();
  }

  initializeListeners() {
    this.$isPanelVisible
      ?.pipe(takeUntil(this.ngDestroyed$), skip(1))
      ?.subscribe((data) => !data && this.onFocusOut());
  }

  initializeObservables() {
    this.$backDrop = this.connecetedOverlay?.backdropClick.pipe(
      map(() => false)
    );
    if (this.$backDrop)
      this.$isPanelVisible = merge(this.$focus, this.$backDrop);
  }

  ngOnInit(): void {
    this.initializeValues();
  }

  initializeValues() {
    !this.selected.length && this._dropdownFactory.initializeSelection();
    this.dropdownWidth =
      this.dropdownCont.nativeElement.getBoundingClientRect().width;
  }

  public onFocus() {
    if (this.focused) return;
    this._focusBehaviorSubject.next(true);
    this.focused = true;
    this._cdr.detectChanges();
    this.stateChanges.next();
  }

  public onSelectOption(value: string) {
    this.currentSelection = value;
    this._dropdownFactory.setOneSelection(value);
    this.onChange(this.selected);
  }

  private get _dropdownFactory(): DropdownFactoryInterface {
    try {
      if (this.multiple) {
        return this._createMultipleSelectionFactory();
      } else {
        return this._createSingleSelectionFactory();
      }
    } catch (e) {
      this._handleDropdownErr(e);
    }
  }

  private _createMultipleSelectionFactory(): DropdownFactoryInterface {
    const arrayError: string = "The value must be array";
    return {
      setOneSelection: (value) => {
        if (!Array.isArray(this.selected)) throw new Error(arrayError);
        if (this.selected.includes(value)) {
          this.selected = this.selected.filter(
            (item: string) => item !== value
          );
          this.currentSelection = "";
        } else {
          this.selected = this.sortItems([...this.selected, value]);
        }
      },
      initializeSelection: () => (this.selected = []),
      writeValue: (value) => {
        if (value && !Array.isArray(value)) throw new Error(arrayError);
        this.selected = this.sortItems(value as string[]);
        this.currentSelection = this.selected[0];
        this._cdr.detectChanges();
      },
      restructureSelection: () => {
        if (!Array.isArray(this.selected) || !this.selected?.length) return;
        const values = this.dropdownItems.map((item) => item.value);
        const filteredItems = this.selected.filter((x) => values.includes(x));
        if (filteredItems.length < this.selected.length) {
          this.selected = filteredItems;
          this.onChange(this.selected);
        }
      },
    };
  }

  private _createSingleSelectionFactory(): DropdownFactoryInterface {
    const stringError: string = "The value must be string";
    return {
      setOneSelection: (value) => {
        if (typeof value !== "string") throw new Error(stringError);
        this._focusBehaviorSubject.next(false);
        this.selected = value;
      },
      initializeSelection: () => (this.selected = ""),
      writeValue: (value) => {
        if (value && typeof value !== "string") throw new Error(stringError);
        this.selected = value || "";
        this.currentSelection = this.selected as string;
        this._cdr.detectChanges();
      },
      restructureSelection: () => {
        if (!this.selected) return;
        const hasNoValues =
          this.dropdownItems.length === 0 ||
          this.dropdownItems.every((item) => item.value != this.selected);
        if (hasNoValues) {
          this.selected = "";
          this.onChange(this.selected);
        }
      },
    };
  }

  private _handleDropdownErr(errorTxt: string) {
    console.error(errorTxt);
  }

  public sortItems(list: string[]) {
    if (!list?.length) return [];
    const indexMap = this.dropdownItems.reduce(
      (final: any, item: any, i: number) => final.set(item.value, i),
      new Map<any, number>()
    );
    return list.sort((val1, val2) => indexMap.get(val1) - indexMap.get(val2));
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (
      event.ctrlKey &&
      event.key === "a" &&
      Array.isArray(this.selected) &&
      this.focused
    ) {
      // Select all items
      this.selected =
        this.selected?.length === this.dropdownItems?.length
          ? []
          : (this.dropdownItems.map((el) => el.value) as string[]);
      this.onChange(this.selected);
      event.preventDefault(); // Prevent default behavior (e.g., selecting all text on the page)
    }
    if (event.key === "Escape") this._focusBehaviorSubject.next(false);
  }
}
