import { Pipe, PipeTransform } from "@angular/core";
import { CountryCodeDataInterface } from "../phone-input.model";
import { DropdownInputInterface } from "@iris/dropdown/models/dropdown.model";

@Pipe({
  name: "countryCodeOptions",
})
export class CountryCodeOptionsPipe implements PipeTransform {
  transform(
    contryCodeDataArr: CountryCodeDataInterface[]
  ): DropdownInputInterface[] {
    if (!contryCodeDataArr) return [];
    return contryCodeDataArr.map((countryCodeData) => ({
      label: `${countryCodeData.value} ${countryCodeData.data.countryName}`,
      value: countryCodeData.value,
    }));
  }
}
