import { Pipe, PipeTransform } from "@angular/core";
import { DropdownInputInterface } from "../models/dropdown.model";
import { itemSize } from "../utils/dropdown-style.util";

@Pipe({
  name: "dropdownHeight",
})
export class DropdownHeightPipe implements PipeTransform {
  transform(items: DropdownInputInterface[]): number {
    return items?.length * itemSize;
  }
}
