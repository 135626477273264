<!-- This button triggers the overlay and is it's origin -->
<div
  [attr.aria-labelledby]="_formField?.getLabelId()"
  (click)="onFocus()"
  class="cp-mat-dropdown-container cp-cursor-pointer cp-w-100 display-flex align-items-center cp-mat-custom-input"
  [ngClass]="{ 'cp-mat-custom-input--focus': focused }"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  #dropdownCont
>
  <ng-container
    [ngTemplateOutlet]="selectedTemplateRef || defaultSelectedTemplate"
    [ngTemplateOutletContext]="{ $implicit: selected }"
  ></ng-container>

  <mat-icon class="cp-mat-dropdown-container__dropdown-icon display-flex center"
    >arrow_drop_down</mat-icon
  >

  <!-- This template displays the overlay content and is connected to the button -->
  <ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayHasBackdrop
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="($isPanelVisible | async) || false"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  >
    <div
      [style.width.px]="dropdownWidth"
      class="cp-mat-dropdown-container__dropdown"
    >
      <ng-container *ngIf="dropdownItems?.length; else noData">
        <input
          *ngIf="searchable"
          type="text"
          cpAutofocus
          [placeholder]="searchPlaceholder"
          class="cp-w-100 cp-mat-dropdown-container__dropdown__input"
          [(ngModel)]="searchText"
        />
        <ng-container *ngIf="dropdownItems | searchFilter : searchText as list">
          <ul class="cp-mat-dropdown-container__dropdown__list" #listElement>
            <cdk-virtual-scroll-viewport
              [itemSize]="itemSize"
              class="cp-mat-dropdown-container__dropdown__list__viewport"
              [style.height.px]="list | dropdownHeight"
            >
              <li
                #listItemElement
                *cdkVirtualFor="let option of list"
                class="example-item"
                (click)="onSelectOption(option?.value)"
                class="cp-mat-dropdown-container__dropdown__list__item cp-cursor-pointer"
                [class.cp-mat-dropdown-container__dropdown__list__item--primary-color]="
                  option?.value | checkedList : selected
                "
                [class.cp-mat-dropdown-container__dropdown__list__item--selected]="
                  currentSelection === option?.value && selected?.length
                "
              >
                <mat-checkbox
                  [checked]="option?.value | checkedList : selected"
                  *ngIf="multiple"
                  class="cp-multiselect-checkbox"
                >
                  <ng-container
                    [ngTemplateOutlet]="
                      optionTemplateRef || defaultOptionTemplate
                    "
                    [ngTemplateOutletContext]="{ $implicit: option }"
                  ></ng-container>
                </mat-checkbox>
                <span class="cp-text-overflow-ellipses" *ngIf="!multiple">
                  <ng-container
                    [ngTemplateOutlet]="
                      optionTemplateRef || defaultOptionTemplate
                    "
                    [ngTemplateOutletContext]="{ $implicit: option }"
                  ></ng-container
                ></span>
              </li>
            </cdk-virtual-scroll-viewport>
          </ul>
          <div
            *ngIf="!list?.length"
            class="cp-mat-dropdown-container__dropdown__list__no-results"
          >
            <span>No results for {{ searchText }}</span>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #noData>
      <span class="cp-mat-dropdown-container__dropdown__no-data"
        >No items to show</span
      >
    </ng-template>
  </ng-template>
</div>

<ng-template #defaultSelectedTemplate let-selected>
  <input
    type="text"
    [placeholder]="placeholder"
    readonly
    class="cp-mat-dropdown-container__input cp-w-100"
    [value]="selected | dropdownLabels : dropdownItems"
  />
</ng-template>

<ng-template #defaultOptionTemplate let-option>
  {{ option?.label }}
</ng-template>
