import { Pipe, PipeTransform } from "@angular/core";
import { DropdownInputInterface } from "../models/dropdown.model";

@Pipe({
  name: "searchFilter",
})
export class SearchFilterPipe implements PipeTransform {
  transform(options: DropdownInputInterface[], searchText: string): unknown {
    if (!searchText) return options;
    return options.filter((option) =>
      option.label.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    );
  }
}
